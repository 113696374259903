import { useParams } from "react-router-dom";
import ContentFrame from "../components/contentFrame";
import { useEffect, useState } from "react";
import ApiService from "../utils";

export default function Item(){
    const {id} = useParams();
    const [frames, setFrames] = useState([]);

    useEffect(() => {
        ApiService.getArticleTitle(id).then((data) => {
            const items = data.article.frames.map(item =>({
                cols: item.cols,
                rows: item.rows,
                gap: item.gap,
                styling: item.styling,
                order: item.order,
                blocks: item.blocks,
                background: data.background
            }));
          // do something
          setFrames(items);
        });
      },[]);
    /*  
    const frames = [
        {content: '# The Quick Brown Fox Jumped Over The Lazy Dog \n\nLorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. \n > The Quick Brown Fox Jumped Over The Lazy Dog \n\n Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.'},
        {content: '![Drag Racing](../images/bg4.jpg)'}
    ];

    const frames2 = [
        {content: '# The Quick Brown Fox Jumped Over The Lazy Dog', spancols: "col-span-full"},
        {content: '![Drag Racing](../images/tinaNL3-154-1-edited-1.jpg) \n ## Eerste column \n Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        {content: '![Drag Racing](../images/costa-rica.jpg) \n ## Tweede column \n Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
        {content: '![Drag Racing](../images/252157609.jpg) \n ## Derde column \n Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'},
    ];

    const frames3 = [
        {content: '# Meer lezen \n The Quick Brown Fox Jumped Over The Lazy Dog.', spancols: 'col-span-2'},
        {content: <ItemCard title="Costa-Rica" tagline="Some tagline" type="blog" picture="./images/costa-rica.jpg"/>, type: "component"},
        {content: <ItemCard onDataUpdate={()=>{}} title="Ibis Hotels" tagline="Some tagline" type="project" picture="./images/252157609.jpg"/>, type: "component"},
    ];

    */

    return(
        <>
        {frames.map((frame, index) => (
            <ContentFrame contentBlocks={frame.blocks} gap={frame.gap} cols={frame.cols} rows={frame.rows} style={frame.styling} key={frame.order}/>
        ))}
        </>
    );
}

{/*

            <div className="aspect-video">
                <Slide title="The Quick Brown Fox Jumped Over The Lazy Dog" tagline="The Quick Brown Fox Jumped Over The Lazy Dog" type="project" picture="./images/bg.jpg" visible="true"/>
            </div>
            <ContentFrame contentFrames={frames} cols="grid-cols-[1fr_2fr]" rows="grid-rows-1" gap="gap-12" style="py-10 px-10 "/>
            <ContentFrame contentFrames={frames2} cols="grid-cols-3" rows="grid-rows-1" gap="gap-12" style=" bg-violet-600 py-20 px-20 text-center prose-p:text-lg"/>
            <ContentFrame contentFrames={frames3} cols="grid-cols-2" rows="grid-rows-[auto_auto]" gap="gap-12" style="mb-10 py-20 px-10 bg-slate-800"/>

*/}