import { Link, useLocation } from "react-router-dom";

export default function Navigation(){
    const location = useLocation();
    const { pathname } = location;

    const navigationLinks = [
        {text: "Home", path: "/"},
        {text: "Showcase", path: "/showcase"},
        {text: "About", path: "/about"},
    ];

    return(<>
        <nav>
            <ul className="flex space-x-4 py-5 px-10 font-semibold text-zinc-50 bg-indigo-600 rounded-full flex-wrap max-w-fit ml-auto my-8 select-none">
                {navigationLinks.map((link, index) => {
                    if(link.path === pathname){
                        return(<li key={index} className=" text-amber-300"><Link to={link.path}>{link.text}</Link></li>);
                    }else{
                        return(<li key={index} className="hover:text-amber-300"><Link to={link.path}>{link.text}</Link></li>);
                    }
                })}
            </ul>
        </nav>
    </>);
}