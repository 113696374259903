import { Link } from 'react-router-dom';
import Blog from '../images/blog.svg';
import Work from '../images/work.svg';
import ArrowRight from '../images/arrow-right.svg';

export default function OptionCard(props){
    const {title, subtitle, type, route} = props;

    let color;
    let graphic = 'none';
    let btn = 'bg-red-500'

    switch(type){
        case 'blog':
                color = 'bg-gradient-to-r from-cyan-500 to-blue-500';
                graphic = Blog;
                btn = 'bg-cyan-400'
            break;
        case 'work':
                color = 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500';
                graphic = Work;
                btn = 'bg-pink-300'
            break;
        default:
            color = 'bg-gradient-to-r from-emerald-500 to-teal-500'
            graphic = 'none'
            btn = 'bg-teal-600'
    }

    return(
        <Link to={route}>
        <div className={`hover:scale-105 transition-all relative flex flex-col w-full h-full rounded-3xl p-8 justify-center select-none ${color}`}> 
            <div className="w-2/3">
                <h2 className="text-3xl text-white font-bold">{title}</h2>
                <p className="text-2xl mt-6 text-white">{subtitle}</p>
            </div> 
            <div className={`${btn} absolute z-10 flex items-center justify-center right-8 bottom-8 rounded-full w-16 h-16 mt-4 text-white font-bold`}><img alt="" src={ArrowRight}/></div>
            <img className="absolute bottom-12 right-12 w-1/2 " src={graphic} alt=""/>
        </div>
        </Link>
    );
}