import React, { createElement } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ItemCard } from './itemCard';
import OptionCard from './optionCard';
import ItemSpotlight from './itemSpotlight';

export default function TextBlock(props) {
  const { content, span, style, component } = props;
  const componentMap = {
    "ItemCard": ItemCard,
    "OptionCard": OptionCard,
    "ItemSpotlight": ItemSpotlight
  };

  const isJson = (str) => {
    try {
      const styledString = JSON.parse(str);
      return styledString;
    } catch (err) {
      return null;
    }
  };

  let SelectedComponent = false;

  if(component){
    SelectedComponent = componentMap[component.name];
  }

  return (
    <div className={`prose max-w-none w-full prose-neutral text-white prose-p:text-white prose-headings:text-white`} style={isJson(style)}>
      {SelectedComponent ? (
        createElement(SelectedComponent, component.props)
      ) : (
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      )}
    </div>
  );
}
