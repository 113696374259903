import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowDown from '../images/arrow-down.svg';
import ArrowLeft from '../images/arrow-left.svg';
import ArrowRight from '../images/arrow-right.svg';
import { ApiService } from '../utils';

export function Slide(props){
    const { title, tagline, picture, type, visible} = props;
    
    if(visible){
        return (
        <div className="w-full h-full relative p-10 rounded-2xl overflow-hidden" style={{backgroundImage: "url("+picture+")",backgroundSize: "cover"}}>
            <p className="text-2xl text-white font-bold uppercase mb-10">{type}</p>
            <h2 className="text-6xl text-white font-bold font mb-4">{title}</h2>
            <p className="text-2xl text-white">{tagline}</p>
            {/*link ? (<a href={link} className="relative z-10 px-8 py-4 rounded-lg mt-4 inline-block bg-slate-400 text-white font-bold">Go</a>) : null*/}
        </div>
        );
    }else{return;}
}

export function Slider(){
    const [active, setActive] = useState(0);
    const [contentSlides, setContentSlides] = useState([]);
    const [link, setLink] = useState('');
  
    useEffect(() => {
      ApiService.getAllFeatured().then((data) => {
        const slides = data.map(item => ({
          title: item.Title,
          tagline: item.Description,
          images: item.Images,
          type: item.Type,
          visible: false,
          link: 'showcase/'+item.Link
        }));
        setContentSlides(slides);
      });
    }, []); // Run only once on mount
  
    useEffect(() => {
      setLink(contentSlides[active]?.link || ''); // Use optional chaining to handle undefined
    }, [active, contentSlides]);
  
    const max = contentSlides.length;
    const Next = () => setActive((prevActive) => (prevActive + 1 + max) % max);
    const Prev = () => setActive((prevActive) => (prevActive - 1 + max) % max);    

    useEffect(() => {
      const intervalId = setInterval(() => Next(), 5000);
  
      return () => {
        clearInterval(intervalId);
      };
    }, [Next]);

    return (
        <div className="w-full h-full relative select-none">
            {contentSlides.map((imageObject, index) => (
                <Slide 
                    key={index}
                    title={imageObject.title}
                    tagline={imageObject.tagline}
                    picture={`../images/${imageObject.images.cover}`}
                    type={imageObject.type}
                    visible={index === active}
                    link={`showcase/${imageObject.link}`}
                />
            ))}
            <div className="absolute bottom-8 pr-8 pl-8 z-10 flex w-full justify-start">
                <button onClick={Prev} className="px-2 py-4 rounded-lg text-white font-bold"><img alt="" className="inline" src={ArrowLeft}/></button>
                <div className="px-2 py-4 rounded-lg ml-2 text-white font-bold">{active+1} / {contentSlides.length}</div>
                <button onClick={Next} className="px-2 py-4 rounded-lg ml-2 text-white font-bold"><img alt="" className="inline" src={ArrowRight}/></button>
                <Link to={link} className="text-1xl px-8 py-4 rounded-full transition-all inline-block hover:scale-105 active:scale-110 bg-indigo-600 ml-auto text-zinc-50 hover:text-amber-300 font-semibold">Read more <img alt="" className="inline" src={ArrowDown}/></Link>
            </div>
        </div>
    );
}