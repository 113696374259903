import ItemGrid from "../components/itemGrid";
import ItemNavigation from "../components/itemNavigation";
import ItemSpotlight from "../components/itemSpotlight";
import Navigation from "../components/navigation";
import { useState } from "react";

export default function Showcase(){

    // get the state of the filter navigation
    const [filterData, setFilterData] = useState();

    const handleDataUpdate = (data) => {
        setFilterData(data); 
      };

    return (<>
        <div className="w-[1212px] m-auto">
        <ItemNavigation onDataUpdate={handleDataUpdate}/>
        </div>
        <div className="mt-8">
        <ItemGrid filter={filterData}/>
        </div>
    </>);
}