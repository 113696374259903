import { useEffect, useState } from "react";
import { ItemCard } from "./itemCard";
import ApiService from "../utils";

export default function ItemGrid(props) {
    const { filter } = props;
    const [itemCard, setItemCard] = useState([]);

    useEffect(() => {
        ApiService.getAllArticleMeta().then((data) => {
          const items = data.map(item => ({
            title: item.Title,
            tagline: item.Description,
            Images: item.Images,
            type: item.Type,
            link: item.Link
          }));
        
          setItemCard(items);
          // do something
        });
      }, []);
    
    let sizes = [1, 0, 1, 1, 0, 0];

    return (
<div className="m-auto w-[1212px] h-[700px] px-4 grid grid-cols-3 grid-rows-[repeat(10_,_200px)] gap-6">
                    {itemCard.map((items, index) => (
                        filter === items.type || filter === 'all' ? (
                    
                            <ItemCard
                                size={filter === "all" ? sizes[(index + sizes.length) % sizes.length] : 0}
                                key={index}
                                title={items.title}
                                //tagline={filter !== "all" ? "" : items.tagline}
                                picture={`./images/${items.Images.thumbnail}`}
                                type={filter !== "all" ? "" : items.type}
                                link={items.link}
                            />
                        ) : null
                    ))}
                </div>
    );
}

/*     const itemCards = [
    {title: "test", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "large", link: "/showcase/test"},
    {title: "test2", tagline: "test", picture: "./images/bg3.jpg", type: "project", size: "small", link: "/showcase/test"},
    {title: "test3", tagline: "test", picture: "./images/bg5.jpg", type: "blog", size: "la,rge" link: "/showcase/test"},
    {title: "test4", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "large", link: "/showcase/test"},
    {title: "test5", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "small", link: "/cshowcase/test"},
    {title: "test6", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "small", link: "/showcase/test"},
    {title: "test", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "large", link: "/showcase/test"},
    {title: "test2", tagline: "test", picture: "./images/bg3.jpg", type: "project", size: "small", link: "/showcase/test"},
    {title: "test3", tagline: "test", picture: "./images/bg5.jpg", type: "blog", size: "large", link: "/showcase/test"},
    {title: "test4", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "large", link: "/showcase/test"},
    {title: "test5", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "small", link: "/cshowcase/test"},
    {title: "test6", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "small", link: "/showcase/test"},
    {title: "test", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "large", link: "/showcase/test"},
    {title: "test2", tagline: "test", picture: "./images/bg3.jpg", type: "project", size: "small", link: "/showcase/test"},
    {title: "test3", tagline: "test", picture: "./images/bg5.jpg", type: "blog", size: "large", link: "/showcase/test"},
    {title: "test4", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "large", link: "/showcase/test"},
    {title: "test5", tagline: "test", picture: "./images/bg4.jpg", type: "blog", size: "small", link: "/cshowcase/test"},
    {title: "test6", tagline: "test", picture: "./images/bg.jpg", type: "project", size: "small", link: "/showcase/test"},
];

*/