import ArrowRight from '../images/arrow-right.svg';
import { Link } from "react-router-dom";

export function ItemCard(props){
    const { title, tagline, picture, link, type, size} = props;
    
        return (
        
        <div className={`${size === 1 ? "row-span-2" : "row-span-1"} hover:scale-105 hover:shadow-xl hover:z-20 active:z-20 active:scale-110 active:shadow-2xl select-none transition-all w-full h-full relative p-10 rounded-2xl overflow-hidden`} style={{backgroundImage: "url("+picture+")",backgroundSize: "cover"}}>
            <Link to={link}>
            <p className="z-10 text-1xl text-white font-bold uppercase mb-1 relative text-left">{type}</p>
            <h2 className="z-10 text-2xl text-white font-bold font mb-1 relative text-left ">{title}</h2>
            <p className="z-10 text-lg text-white relative text-left">{tagline}</p>
            <a href={link} className="absolute z-10 flex items-center justify-center right-8 bottom-8 rounded-full w-16 h-16 mt-4 bg-indigo-900 text-white font-bold"><img alt="" src={ArrowRight}/></a>
            <div className="absolute w-full h-full top-0 left-0 transition-all bg-gradient-to-b from-[rgba(100,0,100,0.6)] -z-1"></div>
            </Link>
        </div>
   
        );
}