import { Slider } from "../components/carousel";
import OptionCard from "../components/optionCard";

export default function Home(){
    return (
    <>
        <div className="m-auto mt-auto w-[1212px] grid grid-rows-3 grid-cols-3 gap-6">
            <div className="col-span-2 row-span-3 aspect-square">
                <Slider className="col-span-2 row-span-3"/>
            </div>
            <OptionCard title='Take a look at my work' subtitle='' type='work' route='/showcase#project' />
            <OptionCard title='Read my blog' subtitle='' type='blog' route='/showcase#blog' />
            <OptionCard title='Learn more about me' subtitle='Am I the right fit for you? Find out!' type='' route='/about' />
        </div>

    </>
    );
}