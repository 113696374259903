export const ApiService = {
    urlApi: 'https://api.ritchiedonkers.nl',
    options: {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
    },

    async getAllFeatured(){
        const response = await fetch(`${ApiService.urlApi}/article/featured`);
        const data = await response.json();
        return data;
    },
    async getAllArticleMeta(){
        const response = await fetch(`${ApiService.urlApi}/article`);
        const data = await response.json();
        return data;
    },
    async getArticleTitle(canonical){
        const response = await fetch(`${ApiService.urlApi}/article/${canonical}`);
        const data = await response.json();
        return data;
    },
    async getSpotlight(canonical){
        const response = await fetch(`${ApiService.urlApi}/spotlight/${canonical}`);
        const data = await response.json();
        return data;
    },

    async getAllPosts() {
        const response = await fetch(`${ApiService.urlApi}/article`);
        const data = await response.json();
        return data;
    },

    async getPosts() {
        const response = await fetch(`${ApiService.urlApi}/posts`);
        const data = await response.json();
        return data;
    },

    async getPost(canonical) {
        const response = await fetch(`${ApiService.urlApi}/posts/item/${canonical}`);
        const data = await response.json();
        return data;
    },

    async getShowcases() {
        const response = await fetch(`${ApiService.urlApi}/showcase`);
        const data = await response.json();
        return data;
    },

    async getShowcase(canonical) {
        const response = await fetch(`${ApiService.urlApi}/showcase/item/${canonical}`);
        const data = await response.json();
        return data;
    }
};

export default ApiService;
