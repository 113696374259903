import { useState } from 'react';
import ArrowBack from '../images/arrow-back.svg';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export default function ItemNavigation(props){
    const {onDataUpdate} = props;
    const { hash } = useLocation();

    const [activeFilter, setFilter] = useState(hash.substring(1) || 'all');
    const linkCategories = [
        {text: "All", filter: "all"},
        {text: "Projects", filter: "project"},
        {text: "Blogs", filter: "blog"},
    ];

    const clickHandler = (f) => {
        setFilter(f);
        updateParent();
    }

    const updateParent = () => {
        onDataUpdate(activeFilter);
      };

    return(
        <>
            <div className="flex justify-between [&>ul]:flex my-4 border-b-4 pb-2 text-white text-lg border-indigo-600 select-none">
                <ul className="space-x-4">
                    <li className="hover:text-indigo-200"><img src={ArrowBack} className="inline mr-1" alt=""/><Link to="/">Back</Link></li>
                </ul>
                <ul className="space-x-4">
                    {linkCategories.map((link, index)=>{
                        updateParent();
                        if(activeFilter === link.filter){
                            return(<li key={index} onClick={() => clickHandler(link.filter)} className="relative px-2 cursor-pointer before:absolute before:rounded-full before:w-full before:left-0 before:-bottom-3 before:border-b-4 before:border-indigo-400 text-indigo-300">{link.text}</li>);
                        }else{
                            return(<li key={index} onClick={() => clickHandler(link.filter)} className="relative px-2 cursor-pointer transition-all hover:text-indigo-200">{link.text}</li>);
                        }
                    })}
                </ul>
                <ul className="space-x-4"></ul>
            </div>
        </>
    );
}
