import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Slide } from "./carousel";

export default function ItemSpotlight(slides){
    const [active, setActive] = useState(0);
    const contentSlides = [
        {title: 'De theaterbeleving in een modern jasje voor Stage Entertainment', tagline: 'How can we innovate in the stage entertainment business while carrying on it’s rich legacy?', picture: '/images/bg.jpg', type: 'blog', visible: false, link: 'stage_entertianment'},
        {title: '2', tagline: 'Tweede slide', picture: '/images/bg4.jpg', type: 'project', visible: false, link: '2'},
        {title: '3', tagline: 'derde slide', picture: '/images/bg3.jpg', type: 'derde', visible: false, link: '3'},
        {title: '4', tagline: 'vierde slide', picture: '/images/bg5.jpg', type: 'derde', visible: false, link: '4'}
    ];

    const max = slides.slides.length;
    const Next = () => {setActive((active+1 + max) % max)}
    const Prev = () => {setActive((active-1 + max) % max)}

    useEffect(()=>{
        const intervalId = setInterval( () => Next(), 5000);

        return ()=>{
            clearInterval(intervalId);
         }
    })
    console.log(slides.slides);
    return (
        <div className="w-full h-full relative">
            {slides.slides.map((imageObject, index) => (
                <Slide 
                    key={index}
                    title={imageObject.title}
                    tagline={imageObject.tagline}
                    picture={imageObject.picture}
                    type={imageObject.type}
                    visible={index === active}
                    link={`showcase/${imageObject.link}`}
                />
            ))}
            <div className="absolute bottom-8 pr-8 pl-8 z-10 flex w-full justify-start">
                <button onClick={Prev} className="px-8 py-4 rounded-lg bg-indigo-900 text-white font-bold">Previous</button>
                <div className="px-8 py-4 rounded-lg ml-4 bg-indigo-900 text-white font-bold">{active+1} / {slides.slides.length}</div>
                <button onClick={Next} className="px-8 py-4 rounded-lg ml-4 bg-indigo-900 text-white font-bold">Next</button>
                <Link to={slides.slides[active].link} className="px-8 py-4 rounded-lg bg-indigo-900 ml-auto text-white font-bold">Go!</Link>
            </div>
        </div>
    );
}