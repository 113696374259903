import TextBlock from "./textBlock";

export default function ContentFrame(props) {
    const { cols, rows, gap, style, contentBlocks} = props;
    const styledString = JSON.parse(style);
    return (
        <>
            <div className={`w-full m-auto grid ${cols} ${rows}`} style={styledString}>

                {contentBlocks.map((frame, index) => (
                    <TextBlock key={index} content={frame.content} span={frame.span} style={frame.styling} component={frame.component}/>
                ))}
            </div>
        </>
    );
}
