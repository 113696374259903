import ItemGrid from "../components/itemGrid";
import ItemNavigation from "../components/itemNavigation";
import ItemSpotlight from "../components/itemSpotlight";
import Navigation from "../components/navigation";
import { useState } from "react";

export default function Spotlight(){
    const [filterData, setFilterData] = useState();
    const handleDataUpdate = (data) => {
        setFilterData(data); 
      };

    return (<>
        <div className="w-[1212px] m-auto">
        <ItemNavigation onDataUpdate={handleDataUpdate}/>
        <ItemSpotlight/>
        </div>
    </>);
}