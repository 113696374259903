import {Route, Routes} from "react-router-dom"
import Navigation from "./components/navigation"
import Home from "./pages/Home"
import About from "./pages/About"
import Showcase from "./pages/Showcase"
import Item from "./pages/Item"
import './index.css';
import Spotlight from "./pages/Spotlight"

export default function App(){
    return (
    <>
    {/* <nav>
        <ul>
            <li className="font-bold text-2xl"><Link to="/">Home</Link></li>
            <li className="font-bold text-2xl"><Link to="/about">About</Link></li>
            <li className="font-bold text-2xl"><Link to="/showcase">Showcase</Link></li>
        </ul
    </nav> */}

    <div className="w-[1212px] m-auto">
    <div className="w-[1212px] m-auto">
    <Navigation />
    </div>
    <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/showcase" element={<Showcase />}/>
        <Route path="/showcase/" element={<Showcase />}/>
        <Route path="/showcase/:id" element={<Item />}/>
        <Route path="/showcase#blog" element={<Showcase/>}/>
        <Route path="/showcase#project" element={<Showcase/>}/>
        <Route path="/spotlight/:id" element={<Spotlight/>}/>
    </Routes>
    </div>
    </>);
}